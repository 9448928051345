import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import { loginOneSignal } from '../one-signal';

export const OneSignalLogin = () => {
  const userData = useSelector((state: RootState) => state.auth.userData);

  useEffect(() => {
    if (userData?.consumerId) {
      console.log(
        `loginOneSignal(${userData.consumerId}, ${userData.consents.pushNotificationsConsent})`,
      );
      loginOneSignal(userData.consumerId, userData.consents.pushNotificationsConsent);
    }
  }, [userData?.consumerId]);

  return <></>;
};
