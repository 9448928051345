import {
  LinkingOptions,
  NavigationContainer,
  useIsFocused,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { darkTheme } from '../config/theme';
import AuthFlow from '../screens/Authentication/AuthFlow';
import { ScreenNames } from '../screens/ScreenNames';
import { RootState } from '../src/store';
import { pushGAEvent } from '../utils/gaUtils';
import { updateJustLoggedOut } from '../screens/Authentication/slices/authSlice';
import { logoutOneSignal } from '../src/one-signal';

export const isAuthReady = (token: string | null): boolean => {
  return token != null;
};

export default memo(function AuthNavigationContainer(props) {
  const userPoints = useSelector((state: RootState) => state?.userPoints?.total_points_consumed);
  const token = useSelector((state: RootState) => state.auth.token);
  const justLoggedOut = useSelector((state: RootState) => state.auth.justLoggedOut);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const hideOnboarding = useSelector((state: RootState) => state?.auth?.userData?.hideOnboarding);
  const [state, setState] = useImmer({
    navIsReady: false,
  });

  const focused = useIsFocused();
  const stackRef = useRef(createStackNavigator());
  const Stack = stackRef.current;

  const navigationContainerRef = useNavigationContainerRef();
  const routeNameRef: any = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onNavReady = () => {
    setState((draft) => {
      draft.navIsReady = true;
    });
  };

  useEffect(() => {
    if (!focused) return;
    if (!state.navIsReady) return;
    if (token == null) return;
  }, [token, focused, state.navIsReady]);

  useEffect(() => {
    if (!localStorage.getItem('redirectUrl') && !justLoggedOut) {
      localStorage.setItem('redirectUrl', window.location.href);
    } else {
      console.log('logoutOneSignal()');
      logoutOneSignal();
      dispatch(updateJustLoggedOut(false));
    }
  }, []);

  const linking: LinkingOptions<{}> = useMemo(
    () => ({
      enabled: false,
      prefixes: [],
    }),
    [],
  );

  if (!focused) return null;

  return (
    <NavigationContainer
      ref={navigationContainerRef}
      theme={darkTheme}
      linking={linking}
      independent={true}
      onReady={onNavReady}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationContainerRef.getCurrentRoute()?.name;
        const trackScreenView = (currentRoute: string | undefined) => {
          if (currentRoute !== 'login') {
            pushGAEvent('datalayer_initialized', 'user', {
              loggedInStatus: `${
                userData?.consumerId ? t('GA-LOGGED_IN_USER') : t('GA-LOGGED_OUT_USER')
              }`,
              ...(userData?.consumerId && { id: userData?.consumerId }),
              ...(userData?.consumerId && { type: userData?.cluster?.type }),
              ...(userData?.consumerId && {
                customerLtvPoints: userPoints
                  ? userPoints?.activity_consumed + userPoints?.loyalty_consumed
                  : 0,
              }),
              ...(userData?.consumerId && {
                customerLtvCodes: userPoints?.total_codes_entered ?? 0,
              }),
              ...(userData?.consumerId && {
                mktAgreement: userData?.consents?.marketingConsent ? 'yes' : 'no',
              }),
              ...(userData?.consumerId && {
                mktSurveys: userData?.consents?.researchConsent ? 'yes' : 'no',
              }),
              ...(userData?.consumerId && {
                newsletterSubscriber: userData?.consents?.communicationConsent.includes('Email')
                  ? 'yes'
                  : 'no',
              }),
              ...(userData?.consumerId && {
                smsSubscriber: userData?.consents?.communicationConsent.includes('SMS')
                  ? 'yes'
                  : 'no',
              }),
              ...(userData?.consumerId && {
                phoneSubscriber: userData?.consents?.communicationConsent.includes('Phone')
                  ? 'yes'
                  : 'no',
              }),
              ...(userData?.consumerId && { referredMember: userData?.referred ? 'yes' : 'no' }),
              ...(userData?.consumerId && {
                oldPlatformUser: userData?.oldPlatformUser ? 'yes' : 'no',
              }),
              platform: Platform.OS,
              siteSection: currentRoute,
            });
          }
        };

        if (previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;

          //  add the tracker
          trackScreenView(currentRouteName);
        }
      }}>
      <Stack.Navigator initialRouteName={ScreenNames.login} screenOptions={{ headerShown: false }}>
        <Stack.Screen name={ScreenNames.userStack} component={AuthFlow} />
      </Stack.Navigator>
    </NavigationContainer>
  );
});
