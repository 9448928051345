import OneSignal from 'react-onesignal';
import { ENVIRONMENT, ONE_SIGNAL_APP_ID } from 'react-native-dotenv';

export const initOneSignal = () => {
  return OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: ENVIRONMENT === 'localhost' ? true : undefined,
  });
};

export const loginOneSignal = async (externalId: string, consent?: boolean) => {
  await OneSignal.login(externalId);
  OneSignal.User.addTags({ has_external_id: '1', consent: consent ? '1' : '0' });

  setTimeout(() => {
    OneSignal.Slidedown.promptPush();
  }, 5000);
};

export const logoutOneSignal = () => {
  return OneSignal.logout();
};
